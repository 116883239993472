<template>
  <div>
    <table-list
      :fields="fields"
      module="services"
      :show-search="false"
    >
      <template v-slot:id>
        <b-button
          variant="primary"
        >
          <span class="text-nowrap">Service toevoegen</span>
        </b-button>
      </template>
      <template v-slot:row-details="row">
        <b-card no-body>
          <b-card-body class="p-0">
            <b-table
              :fields="detailsFields"
              :items="row.item.services"
              show-empty
              empty-text="No matching records found"
            />
          </b-card-body>
        </b-card>
      </template>
    </table-list>
  </div>
</template>

<script>
import {
  BTable, BButton, BCard, BCardBody,
} from 'bootstrap-vue'
import TableList from '@/views/components/TableList.vue'
import store from '@/store'
import { computed, onUnmounted } from '@vue/composition-api'
import serviceStoreModule from './serviceStoreModule'

export default {
  components: {
    BTable,
    BButton,
    BCard,
    BCardBody,
    TableList,
  },
  setup() {
    const SERVICE_STORE_MODULE_NAME = 'services'

    // Register module
    if (!store.hasModule(SERVICE_STORE_MODULE_NAME)) store.registerModule(SERVICE_STORE_MODULE_NAME, serviceStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SERVICE_STORE_MODULE_NAME)) store.unregisterModule(SERVICE_STORE_MODULE_NAME)
    })

    const fields = computed(() => store.state.services.tableFields)
    const detailsFields = [
      { key: 'name' },
      { key: 'price' },
      { key: 'duration' },
    ]

    return {
      fields,
      detailsFields,
    }
  },
}
</script>

<style lang="scss">
.b-table-details {
  td {
    padding: 0.72rem;
  }
}
</style>
